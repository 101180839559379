<template>
    <div>
  
      <b-row class="mt-2">
        <b-col xl="12" md="12">
          <ag-grid-table ref="tablaHistoricoFinanza" :configUrl="historicoFinanzaConfig" :dataUrl="historicoFinanzaData">
          </ag-grid-table>
        </b-col>
      </b-row>
    </div>
  </template>
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    BSpinner,
    BAlert,
    BTab,
    BLink,
    VBPopover,
    VBTooltip,
  
  } from "bootstrap-vue";
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  import useApiServices from '@/services/useApiServices.js';
  
  export default {
    data() {
      return {
        historicoFinanzaConfig: useApiServices.historicoFinanzaConfig,
        historicoFinanzaData: useApiServices.historicoFinanzaData,
      };
    },
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BCardHeader,
      BFormFile,
      BCardTitle,
      BCardSubTitle,
      BCardText,
      BCardBody,
      BFormSelect,
      BTabs,
      VBPopover,
      VBTooltip,
      BTab,
      BLink,
      BAlert,
      BSpinner,
      AgGridTable
    },
  
    directives: {
      'b-tooltip': VBTooltip,
      "b-popover": VBPopover,
    },
  
    mounted() {
  
    },
    methods: {
    },
  };
  </script>
  
  <style scoped>
  </style>
  